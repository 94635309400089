const CONSTANTS = {
  FILES: {},
  BLANKSPACE: ' ',
  BLANK: '',
  TABCHANGE: 'tsc:tab-change',
  DARK: 'dark',
  LIGHT: 'light',
  CONFIRMMSG: 'Are you sure you want to remove?',
  TRUE: 'true',
  FALSE: 'false',
  FILE: 'Files',
  ERRORREADING: 'Error Reading File',
  UTF8: 'UTF-8',
  RETURNNEWLINE: '\r\n',
  DASH: '-',
  TEMPLATE: '-template',
  TOGGLE: '-toggle',
  OUTPUTFORM: 'output-form',
  CURLYBRAKETSTART: '{',
  CURLYBRAKETEND: '}',
  COMMASPACE: ', ',
  DOUBLEQUOTE: '"',
  QUOTECOLON: '": ',
  QUOTECOLONQUOTE: '": "',
  NEWLINE: '\n',
  BRACKETSTART: '[',
  BRACKETEND: ']',
  COPY: {
    CMD: 'copy',
    INPUT: 'textarea',
  },
  NAMESPACE: {
    PACKAGEJSON: 'packagejson',
  },
  TARGETS: {
    MENU: 'menu',
    OVERLAY: 'overlay',
    SIDEBAR: 'sidebar',
    TOGGLE: 'toggle',
    ONICON: 'onIcon',
    OFFICON: 'offIcon',
    TAB: 'tab',
    PANEL: 'panel',
    CONTENT: 'content',
    CONTAINER: 'container',
    BACKGROUND: 'background',
    VIEW: 'view',
    TBODY: 'tbody',
    TEMPLATE: 'template',
    NOTIFICATION: 'notification',
    OUTPUT: 'output',
    NAV: 'nav',
    GALLERY: 'gallery',
    EMPTY: 'empty',
    INPUT: 'input',
    TEXTAREA: 'textarea',
    DROPZONE: 'dropzone',
  },
  CLASSES: {
    HIDE: 'hide',
    OVERLAYENTER: 'overlayEntering',
    OVERLAYLEAVE: 'overlayLeaving',
    SIDEBARENTER: 'sidebarEntering',
    SIDEBARLEAVE: 'sidebarLeaving',
    ENTERING: 'entering',
    LEAVING: 'leaving',
    TOBACKGROUND: 'toBackground',
    FROMBACKGROUND: 'fromBackground',
    ONBACKGROUND: 'onBackground',
    OFFBACKGROUND: 'offBackground',
    TOVIEW: 'toView',
    FROMVIEW: 'fromView',
    ONICON: 'onIcon',
    OFFICON: 'offIcon',
    ONTOGGLE: 'onToggle',
    OFFTOGGLE: 'offToggle',
    ACTIVETAB: 'activeTab',
    INACTIVETAB: 'inactiveTab',
    ACTIVETABICON: 'activeTabIcon',
    INACTIVETABICON: 'inactiveTabIcon',
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    ACTIVEICON: 'activeIcon',
    INACTIVEICON: 'inactiveIcon',
  },
  CLASS: {
    DELETE: '.delete',
    SIZE: '.size',
  },
  TYPE: {
    BYTE: 'b',
    KB: 'kb',
    MB: 'mb',
  },
  DOM: {
    HTML: 'html',
    SVG: 'svg',
    H1: 'h1',
    LI: 'li',
  },
  DATATYPES: {
    TEXT: 'text',
    CHECKBOX: 'checkbox',
    SIMPLEARRAY: 'simpleArray',
    MULTICOLUMN: 'multipleColumn',
    COMPLEXTABLE: 'complexTable',
    COMPLEXARRAY: 'complexArray',
  },
  BG: {
    PURPLE700: 'bg-purple-700',
    GRAY200: 'bg-gray-200',
  },
  OPACITY: {
    O100: 'opacity-100',
    O0: 'opacity-0',
  },
  TRANSLATE: {
    X0: 'translate-x-0',
    X5: 'translate-x-5',
  },
  POSITION: {
    FIXED: 'fixed',
  },
  OVERFLOW: {
    HIDDEN: 'overflow-hidden',
  },
  INSET: {
    X0: 'inset-x-0',
  },
};

export {CONSTANTS};
